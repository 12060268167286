import Cookies from 'js-cookie'
import packageJson from '../package.json'

const apiUrlToWebSocketUrl = (apiUrl: string) => {
    const url = new URL(apiUrl)
    return `${url.protocol === 'http:' ? 'ws' : 'wss'}://${url.host}`
}

const localhost = false
const version = '2.7.1'
const proxy = null
const clientUrl = 'https://charge.sk'
const apiUrl = 'https://api.charge.sk/api/'

export const config =
    localhost
        ? {
            api: '/api/',
            version: 'dev',
            websocket: 'wss://api.charge.sk/ocpp',
            proxy: packageJson.proxy,
            client: 'http://localhost:3000'
        }
        : {
            api: apiUrl,
            version: version,
            client: clientUrl,
            websocket: apiUrlToWebSocketUrl(apiUrl),
            proxy: proxy
        }
/* process.env.NODE_ENV === 'development'
     ? {
         api: '/api/',
         version: 'dev',
         websocket: 'wss://apidevelopment.charge.sk/ocpp',
         proxy: packageJson.proxy,
         client: 'http://localhost:3000'
     }
     : {
         api: (window as any)._env_.API_URL,
         version: (window as any)._env_.VERSION_NUMBER,
         client: (window as any)._env_.CLIENT_URL,
         websocket: apiUrlToWebSocketUrl((window as any)._env_.API_URL),
         proxy: null
     }*/


export const getToken = () => {
    return Cookies.get('clientToken')
}

export const setToken = (token: string) => {
    Cookies.set('clientToken', token)
}

export const removeToken = () => {
    Cookies.remove('clientToken')
}

export const getDefaultLanguage = () => {
    return Cookies.get('defaultLanguage')
}

export const setDefaultLanguage = (defaultLanguage: string) => {
    Cookies.set('defaultLanguage', defaultLanguage)
    return defaultLanguage;
}

export const getAppKey = () => {
    return 'd572601b-0651-47b8-b53c-832fce02a8e1'
}

export const getMainTenantId = () => {
    // TenantLogin.Charge
    // DEV
    //return '8301fe71-bc7e-49ef-b97b-a16e2744aad9'
    // PROD
    return '9b671b08-c0a7-4ac9-82db-7275e7d6893c'
}

export const getTenant = () => {
    const urlObj = new URL(window.location.href);
    let host = urlObj?.hostname;

    if (host.includes('www.')) {
        host = host.split('www.')[1]
    }

    host = host.split('.')[0]
    switch (host) {
        case 'siemens':
        case 'siemens-test':
        case 'siemens-healthineers':
            return 'siemens';
        case 'nedal-test':
            return 'nedal'
        default:
            return 'charge'   
    }
}