import {
    valueHolderActionCreator,
    asyncActionCreator,
    asyncPagedActionCreator,
} from '../../shared/redux/actions/common'
import {
    CHARGING_SESSION,
    START_CHARGING,
    STOP_CHARGING,
    FETCH_CHARGING_HISTORY,
    CHARGING_HISTORY_FILTER,
    UPDATE_CHARGING_SESSION_CONSUMPTION,
    FETCH_CHARGING_SESSION,
    FETCH_CONNECTOR_PRICING_CONDITIONS,
} from '../const/index'
import { getJson, sendJson } from '../../shared/utils/api'
import { ChargerConnector } from '../../shared/types/charger'

const startChargingErrorCodesMapping = [
    ['1', 'charging.communicationError'],
    ['2', 'charging.errorRejected'],
    ['3', 'charging.errorWrongState'],
    ['5', 'charging.errorProfileRejected'],
]

const stopChargingErrorCodesMapping = [
    ['1', 'charging.errorNotConnectedEndThroughCar'],
    ['2', 'charging.errorRejected'],
    ['3', 'charging.errorWrongState'],
]

export const setChargingSession = (chargingSession: any) => {
    return valueHolderActionCreator(CHARGING_SESSION, chargingSession)
}

export const getChargingSession = (id: string) => {
    return asyncActionCreator(FETCH_CHARGING_SESSION, () => getJson(`ChargingSession/${id}`))
}

export const updateChargingSessionConsumption = (consumedWh: number, importedWh: number, offeredWh: number) => ({
    type: UPDATE_CHARGING_SESSION_CONSUMPTION,
    payload: {
        ConsumedWh: consumedWh,
        ImportedWh: importedWh,
        OfferedWh: offeredWh,
    },
})

export const startCharging = (connectorId: string, pricingConditionId: string) => {
    const startChargingFunction = () =>
        sendJson(`chargingSession/start`, 'POST', { chargingConnectorId: connectorId, pricingConditionId })

    return asyncActionCreator(START_CHARGING, startChargingFunction, {}, startChargingErrorCodesMapping)
}

export const stopCharging = (sessionId: string) => {
    const stopChargingFunction = () => sendJson(`chargingSession/end/${sessionId}`, 'PUT')

    return asyncActionCreator(STOP_CHARGING, stopChargingFunction, {}, stopChargingErrorCodesMapping)
}

export const fetchChargingHistory = (key: any, state: any, filter: { from: Date; to?: Date }) => {
    const toFilter = filter.to ? `&to=${filter.to.toISOString()}` : ''
    return asyncPagedActionCreator(
        FETCH_CHARGING_HISTORY,
        (pageIndex, pageSize) =>
            getJson(
                `chargingSession/me?page=${pageIndex}&pageSize=${pageSize}&from=${filter.from.toISOString()}${toFilter}&sort=start desc`
            ),
        key,
        state
    )
}

export const fetchChargingConnector = (chargingConnector: ChargerConnector) => {
    return async (dispatch: Function) => {
        new Promise((resolve) => {
            const resolvePromises = []
            resolvePromises.push(getJson(`chargingConnector/${chargingConnector.id}`))

            Promise.all(resolvePromises)
                .then((body) => {
                    resolve(true)
                    const data = body[0] as ChargerConnector
                    chargingConnector.state = data.state
                    chargingConnector.availability = data.availability
                })
                .catch(() => {
                    resolve(false)
                })
        })
    }
}

export const setChargingHistoryFilter = (filterId: number) => {
    return valueHolderActionCreator(CHARGING_HISTORY_FILTER, filterId)
}

export const fetchPricingConditionsForConnector = (connectorId: string) => {
    return asyncActionCreator(FETCH_CONNECTOR_PRICING_CONDITIONS, () =>
        getJson(`chargingConnector/pricingConditions/${connectorId}`)
    )
}
