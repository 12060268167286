import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HashRouter as Router, Switch, Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import MainMap from './pages/mainMap/MainMap'
import MainList from './pages/mainList/MainList'
import Charging from './pages/charging/Charging'
import ChargingHistory from './pages/chargingHistory/ChargingHistory'
import LoginPage, { IProps as ILoginPageProps } from './core/login/login'
import Register from './pages/register/Register'
import SplashScreen, { IProps as ISplashPageProps } from './pages/splashScreen/SplashScreen'
import Settings from './pages/settings/Settings'
import RegisterCard from './pages/registerCard/RegisterCard'
import ForgotPassword from './pages/forgotPassword/ForgotPassword'
import ResetPassword from './pages/resetPassword/ResetPassword'
import ConfirmAccount from './pages/confirmAccount/ConfirmAccount'
import usePromiseResult from './shared/utils/usePromiseResult'
import { getJson } from './shared/utils/api'
import { getTenant, getToken } from './config'
import { setPaymentSettings, setUser } from './shared/redux/actions/common'
import { setMapToken } from './redux/actions/locations'
import { getCurrentSession, updateCurrentSession } from './utils/currentSession'
import { UserModel } from './shared/types/user'
import { setTenant } from './redux/actions/users'

export default function AppRouter() {
    const returnUrl = window.location.href.substring(
        window.location.href.lastIndexOf('/charging'),
        window.location.href.length
    )

    const loggedRoutes: Array<RouteProps> = [
        { path: '/', component: MainMap, exact: true },
        { path: '/register', component: Register, exact: false },
        { path: '/main-map', component: MainMap, exact: false },
        { path: '/main-list', component: MainList, exact: true },
        { path: '/charging', component: Charging, exact: false },
        { path: '/charging-history', component: ChargingHistory, exact: true },
        { path: '/settings', component: Settings, exact: false },
        { path: '/register-card', component: RegisterCard, exact: false },
    ]

    const anonymousRoutes: Array<RouteProps> = [
        {
            path: '/login',
            render: (properties: RouteComponentProps) => {
                const props: ILoginPageProps = { ...properties, returnUrl }
                return <LoginPage {...props} />
            },
            exact: false,
        },
        {
            path: '/',
            render: (properties: RouteComponentProps) => {
                const props: ISplashPageProps = { ...properties, returnUrl }
                return <SplashScreen {...props} />
            },
            exact: true,
        },
        { path: '/register', component: Register, exact: false },
        { path: '/forgot-password', component: ForgotPassword, exact: false },
        { path: '/reset-password', component: ResetPassword, exact: false },
        { path: '/confirm-account', component: ConfirmAccount, exact: false },
    ]

    const AnonymousUserModel: UserModel = {
        active: true,
        chargingSessionToken: '',
        confirmed: false,
        customer: '',
        customerId: '',
        email: '',
        firstName: 'Anonymous',
        id: '',
        lastName: '',
        cars: [],
        password: '',
        phone: '',
        profileImage: '',
        rfid: '',
        vip: false,
        carLicensePlate: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        companyId: '',
        taxId: '',
        vatId: '',
        cardType: '',
        cardNumber: '',
        cardExpiration: '',
        paymentRequestId: '',
        showHiddenLocation: false,
        tenants: '',
        tags: [],
    }

    const dispatch = useDispatch()

    const loadTenant = (data: any) => {
        dispatch(setTenant(data))

        if (data && data.texts) {
        }
    }

    const loginState = useSelector((state: any) => state.user.login.value)
    const isLoggedIn: boolean = usePromiseResult(
        'router',
        (inputState) => {
            return new Promise((resolve) => {
                // get tenant information
                const tenant = getTenant()
                getJson(`tenant/${tenant}`).then((data) => loadTenant(data))

                if (getToken()) {
                    if (inputState.isLoggedIn && inputState.user) {
                        resolve(true)
                    } else {
                        const isAnonymousUser: boolean = inputState.isAnonymous
                        const resolvePromises = [
                            getCurrentSession(),
                            getJson('settings/mapToken'),
                            getJson('settings/paymentSettings'),
                        ]

                        if (!isAnonymousUser) {
                            resolvePromises.push(getJson('users/me'))
                        }

                        Promise.all(resolvePromises)
                            .then(([chargingSessions, mapSettings, paymentSettings, user]) => {
                                if (!user || isAnonymousUser) {
                                    user = AnonymousUserModel
                                }
                                dispatch(setPaymentSettings(paymentSettings))
                                dispatch(setUser(user, isAnonymousUser))
                                dispatch(setMapToken(mapSettings.token))
                                updateCurrentSession(dispatch, chargingSessions.items)
                                resolve(true)
                            })
                            .catch(() => {
                                resolve(false)
                            })
                    }
                } else {
                    resolve(false)
                }
            })
        },
        [loginState]
    )

    const routes = isLoggedIn ? loggedRoutes : anonymousRoutes

    return (
        <Router hashType="hashbang">
            <Switch>
                {routes.map((route, index) => (
                    <Route key={index} {...route} />
                ))}
                <Redirect from="*" to="/" />
            </Switch>
        </Router>
    )
}
