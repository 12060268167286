import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    Button,
    MenuItem,
    FormControl,
    Select,
    Typography,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    TableContainer,
    Table,
    TableCell,
    TableBody,
    TableRow,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom'
import { LocationWithChargers } from '../../shared/types/location'
import { useDispatch, useSelector } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useConnectorStatusPeriodicUpdate } from '../../utils/currentChargerConnectorStatus'
import {
    startCharging,
    setChargingSession,
    fetchChargingConnector,
    fetchPricingConditionsForConnector,
} from '../../redux/actions/charging'
import {
    getConnectorLabel,
    ChargerConnectorState,
    ChargerConnectorType,
    ChargerConnectorAvailability,
    Charger,
    ChargerConnector,
} from '../../shared/types/charger'
import { AsyncButton } from './../../shared/components/asyncButton'
import { cardRegister, clearEditState, fetchUser } from '../../redux/actions/users'
import QueryBuilderOutlined from '@material-ui/icons/QueryBuilderOutlined'
import Close from '@material-ui/icons/Close'
import { BottomControlPanel } from '../../core/footer/bottomControlPanelStartCharging'
import PlugIcon from '../../static/icons/icon-plug-blue.svg'
import CardIcon from '../../static/icons/icon-outline-credit-card-blue.svg'
import BoltIcon from '../../static/icons/icon-outline-bolt-blue.svg'
import PowerIcon from '../../static/icons/icon-outline-power-white.svg'
import PowerIconRed from '../../static/icons/icon-outline-power-red.svg'
import RadioButtonUnchecked from '../../static/icons/radio_button_unchecked_blue.svg'
import RadioButtonChecked from '../../static/icons/radio_button_checked_blue.svg'
import DoorHanger from '../../static/icons/door-hanger.svg'
import ElectricalServicesIcon from '../../static/icons/electrical_services.svg'
import { useChargersStatusPeriodicUpdate } from '../../utils/currentChargersStatus'
import { PricingConditionModel } from '../../shared/types/pricingCondition'

const useStyles = makeStyles((theme) => ({
    navigateButton: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        borderRadius: '28px',
        fontSize: '0.7rem',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    navigateButtonWhite: {
        width: '36px',
        height: '45px',
        borderRadius: '14px',
        minWidth: '36px',
        marginBottom: '17px',
        backgroundImage: 'linear-gradient(141deg, #545b5c, #2a2e2f 100%)',
        '&:hover': {
            backgroundColor: 'black',
            color: '#37c0ca',
            borderColor: 'black',
        },
    },
    navigateIcon: {
        marginTop: '10px',
        color: 'white',
        height: '18px',
    },
    subTitle: {
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#37c0ca',
        marginBottom: '21px',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
        marginBottom: '21px',
    },
    smallTitle: {
        fontFamily: "'Muli', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#37c0ca',
    },
    property: {
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
        fontSize: '1.2rem',
        color: '#000000',
    },
    select: {
        textAlign: 'left',
        width: '100%',
        fontFamily: "'Mulish', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
    },
    selectIconRight: {
        width: '12px',
        marginRight: '8px',
        marginLeft: '8px',
        color: 'white',
    },
    selectIconRightContainer: {
        zIndex: 100,
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectIconLeft: {
        width: '18px',
        color: '#37c0ca',
        marginLeft: '8px',
        marginRight: '8px',
    },
    selectIconLeftContainer: {
        zIndex: 100,
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectRoot: {
        paddingRight: '0px !important',
        fontSize: '11px',
        paddingLeft: '25px',
        marginLeft: '10px',
        backgroundPosition: 'left',
        backgroundImage: `url(${PlugIcon})`,
        backgroundSize: '16px 16px',
        backgroundRepeat: 'no-repeat',
        '& .MuiPaper-root': {
            backgroundColor: 'black',
        },
        color: 'white !important',
        backgroundColor: 'black !important',

        border: 'none',
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
        boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.07), 0px 1px 5px 0px rgba(0,0,0,0.06)',
        '&$selected': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: 'black', // updated backgroundColor
        },
    },
    freeNotice: {
        textAlign: 'right',
        paddingBottom: theme.spacing(2),
    },
    pricelistTable: {
        marginBottom: '10px',
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(1),
        },
    },
    contentWrapper: {
        marginBottom: '50px',
        flex: 1,
        justifyContent: 'space-between',
        overflowY: 'hidden',
    },
    pricelistDescription: {
        color: '#b3b3b3',
        textAlign: 'left',
        fontFamily: 'Play',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        padding: theme.spacing(1.5, 1, 1.5, 1),
    },
    scrollable: {
        zIndex: 3,
        overflowY: 'auto',
        padding: '23px 23px 23px 23px',
    },
    selectContainer: {
        marginBottom: '10px',
        height: '45px',
        borderRadius: '14px',
        background: 'black',
        border: 'solid 1px #565b5c',
    },
    dialogContent: {
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: 'inherit',
            '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline',
            },
        },
    },
    titleProgressSpinner: {
        marginLeft: theme.spacing(2),
    },
    chipsWrapInner: {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        width: 'auto',
    },
    infoWrap: {
        zoom: '0.75',
        paddingTop: theme.spacing(2),
        paddingBottom: '162px',
        textAlign: 'left',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent',
        },
    },
    chargingProfileInfoContainer: {
        minWidth: '185px',
        width: '185px',
        height: '146px',
        margin: '0px 33px 0px 0px',
        borderRadius: '10px',
        backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 0) 100%)',
    },
    chargingProfileInfoItemCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    chargingProfileInfoIconContainer: {
        borderBottom: 'solid 1px #565b5c',
    },
    chargingProfileInfoIcon: {
        width: '17px',
        height: '24px',
    },
    chargingProfileInfoVal: {
        marginTop: '17px',
        fontFamily: 'Play',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        borderBottom: 'solid 1px #565b5c',
    },
    chargingProfileInfoDesc: {
        marginTop: '5px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
    },
    bottomDecoration: {
        bottom: '60px',
        left: '0px',
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '187px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    descIcon: {
        color: '#37c0ca',
    },
    navigateButtonContent: {
        marginTop: '8px',
        display: 'block',
        color: 'white',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.86px',
        textAlign: 'center',
    },
    menuItem: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: 'white !important',
        },
        '& .MuiPaper-root': {
            backgroundColor: 'black',
        },
        fontSize: '12px',
        background: '#363b3d',
        color: 'white',
        backgroundPosition: 'left, right 11px top 8px',
        backgroundImage: `url(${RadioButtonUnchecked})`,
        backgroundSize: '14px 14px',
        backgroundRepeat: 'no-repeat',
        marginLeft: '10px',
        paddingLeft: '25px',
        paddingRight: '0px',
        marginRight: '10px',
        minHeight: '30px',
    },
    menuProps: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: '#363b3d !important',
            color: 'white',
            fontSize: '12px',
            backgroundPosition: 'left, right 11px top 8px',
            backgroundImage: `url(${RadioButtonChecked})`,
            backgroundSize: '14px 14px',
            backgroundRepeat: 'no-repeat',
            marginLeft: '10px',
            paddingLeft: '25px',
            minHeight: '30px',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#363b3d',
            borderRadius: '10px',
        },
        '& .MuiList-padding': {
            backgroundColor: '#363b3d',
            borderRadius: '10px',
        },
        '& .MuiPopover-paper': {
            left: '0 !important',
            right: '0 !important',
            margin: '0 23px 0 23px !important',
        },
    },
    dialog: { zoom: '0.75' },
    '@global': {
        '.MuiDialog-paperScrollPaper': {
            backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 1) 100%)',
            borderRadius: '16px',
        },
    },
    dialogTitle: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
        paddingBottom: '16px',
    },
    dialogTextDesc: {
        margin: '0px 0px 0px 0px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
    },
    startChargingButton: {
        minWidth: '116px',
        minHeight: '116px',
        borderRadius: '116px',
        background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,110,124,1) 100%)',
        backgroundSize: '200px 400px',
        WebkitAnimation: '$AnimationName 3s ease infinite',
        MozAnimation: '$AnimationName 3s ease infinite',
        OAnimation: '$AnimationName 3s ease infinite',
        animation: '$AnimationName 3s ease infinite',
    },
    startChargingButtonDisbled: {
        minWidth: '116px',
        minHeight: '116px',
        borderRadius: '116px',
    },
    '@-webkit-keyframes AnimationName': {
        '0%': { backgroundPosition: '0px 307px' },
        '50%': { backgroundPosition: '0% 248px' },
        '100%': { backgroundPosition: '0px 307px' },
    },
    '@-moz-keyframes AnimationName': {
        '0%': { backgroundPosition: '0px 307px' },
        '50%': { backgroundPosition: '0% 248px' },
        '100%': { backgroundPosition: '0px 307px' },
    },
    '@-o-keyframes AnimationName': {
        '0%': { backgroundPosition: '0px 307px' },
        '50%': { backgroundPosition: '0% 248px' },
        '100%': { backgroundPosition: '0px 307px' },
    },
    '@keyframes AnimationName': {
        '0%': { backgroundPosition: '0px 307px' },
        '50%': { backgroundPosition: '0% 248px' },
        '100%': { backgroundPosition: '0px 307px' },
    },
    closeText: {
        color: 'white',
        fontSize: '16px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        fontFamily: "'Mulish', sans-serif",
        borderBottom: '#ffa3a3 solid 1px',
        borderBottomStyle: 'groove',
        padding: '2px',
    },
    closeButton: {
        width: '100%',
        height: '40px',
        borderRadius: '14px',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    chargerImage: {
        height: '100%',
        width: '100%',
    },
}))

interface ChargingStartProps {
    location: LocationWithChargers
}

export const ChargingStart: React.FC<ChargingStartProps> = ({ location }) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { search } = useLocation()

    const isAnonymous = useSelector((state: any) => state.user.login.value.isAnonymous)
    const user = useSelector((state: any) => state.user.login.value.user)
    const startChargingState = useSelector((state: any) => state.startCharging)
    //const editState = useSelector((state: any) => state.user.edit)
    const currency = useSelector((state: any) => state.paymentSettings.value.currency)
    const userId = useSelector((state: any) => state.user.login.value.user.id)
    const locationData = useSelector((state: any) => state.locationDetail.value)

    const pricingConditionsState = useSelector((state: any) => state.connectorPricingConditions)

    const sortedChargers = location.chargers.sort((x, y) => x.name.localeCompare(y.name))
    const sortedConnectorForDefaultCharger = sortedChargers[0]?.connectors.slice().sort((a, b) => a.externalId - b.externalId)[0]

    const [selectedCharger, setSelectedCharger] = useState(sortedChargers[0])
    const [selectedConnector, setSelectedConnector] = useState<ChargerConnector>()
    const [dialogMessage, setDialogMessage] = useState<string>('')
    const [intervalHandler, setIntervalHandler] = useState<number>()
    const [showCardVerificationDialog, setShowCardVerificationDialog] = useState<boolean>()

    const [selectedPricingCondition, setSelectedPricingCondition] = useState<any>()

    //const [manualChange, setManualChange] = useState<boolean>(false)
    const manualChange = useRef(false)

    const allowCharging =
        (selectedConnector && selectedConnector?.state === ChargerConnectorState.Free ||
            selectedConnector?.state === ChargerConnectorState.Preparing ||
            selectedConnector?.inPreparingState === true) &&
        selectedPricingCondition &&
        selectedConnector?.availability === ChargerConnectorAvailability.Available &&
        location.connectedChargers.includes(selectedCharger.id)

    const inProgress = !startChargingState.executed

    const priceForKwh = 'priceForKwh'
    const limitPerSession = 'limitPerSession'
    const maxSpeedOfCharging = 'maxSpeedOfCharging'
    const priceForMinuteOfParking = 'priceForMinuteOfParking'
    const amountOfFreeParking = 'amountOfFreeParking'
    const maximumDurationOfCharging = 'maximumDurationOfCharging'

    const setDefaultConnector = () => {
        let preparedConnector
        sortedChargers.every(function (charger) {
            preparedConnector = charger.connectors.find((i) => i.inPreparingState === true)
            if (preparedConnector && location.connectedChargers.includes(charger.id)) {
                setSelectedCharger(charger)
                setSelectedConnector(preparedConnector)
                return false
            } else {
                return true
            }
        })
        if (!preparedConnector) {
            sortedChargers.every(function (charger) {
                if (location.connectedChargers.includes(charger.id)) {
                    preparedConnector = charger.connectors.find((i) => i.state === 0)
                    setSelectedCharger(charger)
                    return false
                } else {
                    return true
                }
            })

            // preparedConnector = sortedChargers[0].connectors.find((i) => i.state === 0)
            setSelectedConnector(preparedConnector ? preparedConnector : sortedConnectorForDefaultCharger)
        }
    }

    const onChargerSelectionChanged = (event: any) => {
        manualChange.current = true
        const selectedId = event.target.value
        if (selectedId !== selectedCharger.id) {
            const charger = sortedChargers.find((x) => x.id === selectedId)
            const preparedConnector = charger?.connectors.find((i) => i.inPreparingState === true)
            setSelectedCharger(charger!)
            setSelectedConnector(preparedConnector ? preparedConnector : charger!.connectors[0])
        }
    }

    const onConnetorSelectionChanged = async (event: any) => {
        manualChange.current = true
        const selectedId = event.target.value
        if (selectedId !== selectedConnector?.id) {
            const connector = selectedCharger.connectors.find((x) => x.id === selectedId)
            setSelectedConnector(connector!)
        }
    }

    const startGetSession = async () => {
        if (selectedConnector) {
            const result = await dispatch(startCharging(selectedConnector.id, selectedPricingCondition.id))
            dispatch(setChargingSession(result))
        }
    }

    const updateSelectedPricingCondition = (id: any) => {
        let condition = pricingConditionsState.value?.filter((condition: any) => condition.id === id)[0]
        if (!condition) {
            condition = pricingConditionsState.value[0]
        }
        setSelectedPricingCondition(condition)
    }

    const upadteCurrentConnectorState = (event: any) => {
        if (selectedConnector) {
            dispatch(fetchChargingConnector(selectedConnector))
        }
    }

    const getPriceListDatailVal = (amout: number, currency: boolean, permanentRow: boolean) => {
        if (currency) {
            var price = parseFloat(amout.toString()).toFixed(2)
            if (price === '0.00') {
                if (permanentRow) {
                    return 'hideAmout'
                } else {
                    return 'hideRow'
                }
            } else {
                return price
            }
        } else {
            if (amout === 0) {
                if (permanentRow) {
                    return 'hideAmout'
                } else {
                    return 'hideRow'
                }
            } else {
                return amout
            }
        }
    }

    const getConncetorStateIcon = (
        chargerConnectorState: ChargerConnectorState,
        chargerConnectorAvailability: ChargerConnectorAvailability
    ) => {
        var label
        switch (chargerConnectorState) {
            case ChargerConnectorState.Busy:
            case ChargerConnectorState.Error:
            case ChargerConnectorState.OutOfOrder:
            case ChargerConnectorState.Unknown:
                label = <img src={DoorHanger} alt="ElectricalServices" style={{ height: '15px' }} />
                break
            case ChargerConnectorState.Free:
                label = <img src={ElectricalServicesIcon} alt="ElectricalServices" style={{ height: '15px' }} />
                break
            case ChargerConnectorState.Preparing:
                label = <img src={ElectricalServicesIcon} alt="ElectricalServices" style={{ height: '15px' }} />
                break
            default:
                label = <img src={DoorHanger} alt="ElectricalServices" style={{ height: '15px' }} />
        }
        if (chargerConnectorAvailability === ChargerConnectorAvailability.Unavailable) {
            label = <img src={PowerIconRed} alt="ElectricalServices" style={{ height: '15px' }} />
        }
        return label
    }

    const freeChargingAllowed = () => {
        if (selectedPricingCondition) {
            if (selectedPricingCondition.priceForMinuteOfParking !== 0 || selectedPricingCondition.priceForKwh !== 0) {
                return false;
            }
        }
        return true;
    }
    useConnectorStatusPeriodicUpdate(location.id ? location.id : '')
    useChargersStatusPeriodicUpdate()

    useEffect(() => {
        var actualConnector
        var preparingConnector
        var actualCharger
        var preparingCharger
        if (locationData.connectorUpdate !== undefined && locationData.id === location.id) {
            locationData.chargers.forEach((charger: any) => {
                charger.connectors.every((connector: any) => {
                    if (connector.id === selectedConnector?.id) {
                        actualConnector = connector
                        actualCharger = charger
                    }
                    if (connector.state === ChargerConnectorState.Preparing || connector.inPreparingState === true) {
                        preparingConnector = connector
                        preparingCharger = charger
                        return false
                    }
                    return true
                })
            })
            if (preparingCharger && !manualChange.current) {
                setSelectedCharger(preparingCharger)
            } else if (actualCharger) {
                setSelectedCharger(actualCharger)
            }
            if (preparingConnector && !manualChange.current) {
                setSelectedConnector(preparingConnector)
            } else if (actualConnector) {
                setSelectedConnector(actualConnector)
            }
            manualChange.current = false
        }
    }, [
        locationData.connectorUpdate,
        locationData.id,
        location.id,
        selectedCharger.id,
        selectedConnector?.id,
        locationData.chargers,
    ])

    useEffect(() => {
        const params = new URLSearchParams(search)
        const chargerExternalId = params.get('charger')
        const charger = sortedChargers?.find((charger: Charger) => charger.externalId === chargerExternalId)
        if (charger) {
            if (charger.id !== selectedCharger.id) {
                setSelectedCharger(charger)
            }
            const connectorExternalId = params.get('connector')
            let connector = charger?.connectors.find(
                (connector: ChargerConnector) => connector.externalId.toString() === connectorExternalId
            )
            if (!connector) {
                connector = charger.connectors[0]
            }
            if (connector) {
                if (selectedConnector?.id !== connector.id) {
                    setSelectedConnector(connector)
                }
            } else {
                setDefaultConnector()
            }
        } else {
            setDefaultConnector()
        }
        validateUserCardRegistration()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    useEffect(() => {
        const params = new URLSearchParams(search)
        const result = params.get('ResultCode')
        const successfulTransaction = '0'
        if (result === successfulTransaction && selectedConnector && selectedPricingCondition) {
            dispatch(cardRegister(userId, params))
        }
        if (result && result !== successfulTransaction) {
            console.log('AcquirerResponseId', params.get('AcquirerResponseId'))
        }
    }, [dispatch, selectedPricingCondition, selectedConnector, search, userId])

    useEffect(() => {
        return () => {
            dispatch(clearEditState())
        }
    }, [dispatch])

    useEffect(() => {
        if (user.paymentRequestId && user.cardNumber) {
            clearInterval(intervalHandler)
            setShowCardVerificationDialog(false)
        }
    }, [user.paymentRequestId, user.cardNumber, intervalHandler])

    const renderPricingConditionDetails = () => {
        if (selectedPricingCondition) {
            const pricelistDesc = selectedPricingCondition.description
            const tableRowProps = [
                {
                    id: 0,
                    title: priceForKwh,
                    label: currency,
                    amout: getPriceListDatailVal(selectedPricingCondition.priceForKwh, true, true),
                    icon: () => {
                        return (
                            <>
                                <img alt="Card" src={CardIcon} />
                            </>
                        )
                    },
                },
                {
                    id: 1,
                    title: limitPerSession,
                    label: 'kWh',
                    amout: getPriceListDatailVal(selectedPricingCondition.limitPerSession, false, false),
                    icon: () => {
                        return (
                            <>
                                <img alt="Bolt" src={BoltIcon} />
                            </>
                        )
                    },
                },
                {
                    id: 2,
                    title: priceForMinuteOfParking,
                    label: currency,
                    amout: getPriceListDatailVal(selectedPricingCondition.priceForMinuteOfParking, true, true),
                    icon: () => {
                        return (
                            <>
                                <img alt="Card" src={CardIcon} />
                            </>
                        )
                    },
                },
                {
                    id: 3,
                    title: amountOfFreeParking,
                    label: 'min',
                    amout: getPriceListDatailVal(selectedPricingCondition.amountOfFreeParking, false, false),
                    icon: () => {
                        return (
                            <>
                                <QueryBuilderOutlined className={classes.descIcon} />
                            </>
                        )
                    },
                },
                {
                    id: 4,
                    title: maximumDurationOfCharging,
                    label: 'min',
                    amout: getPriceListDatailVal(selectedPricingCondition.maximumDurationOfCharging, false, false),
                    icon: () => {
                        return (
                            <>
                                <QueryBuilderOutlined className={classes.descIcon} />
                            </>
                        )
                    },
                },
                {
                    id: 5,
                    title: maxSpeedOfCharging,
                    label: selectedConnector?.type === ChargerConnectorType.Ac ? 'A' : 'kW',
                    amout: getPriceListDatailVal(selectedPricingCondition.maxSpeedOfCharging, false, false),
                    icon: () => {
                        return (
                            <>
                                <img alt="Bolt" src={BoltIcon} />
                            </>
                        )
                    },
                },
            ]
            return (
                <>
                    <div className={classes.infoWrap}>
                        <TableContainer>
                            <Table size="small" className={classes.pricelistTable}>
                                <TableBody>
                                    {tableRowProps.map((prop) => (
                                        <React.Fragment key={prop.id}>
                                            {prop.amout !== 'hideRow' && (
                                                <TableRow key={prop.id}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className={classes.chargingProfileInfoIconContainer}
                                                    >
                                                        <div className={classes.chargingProfileInfoIcon}>
                                                            {prop.icon()}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className={classes.chargingProfileInfoVal}
                                                    >
                                                        {t(`pricelists.${[prop.title]}`)}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        className={classes.chargingProfileInfoVal}
                                                        style={{
                                                            width: '150px',
                                                            color: '#37c0ca',
                                                            borderBottom: 'solid 1px #565b5c',
                                                        }}
                                                    >
                                                        {prop.amout === 'hideAmout'
                                                            ? t('pricelists.free')
                                                            : prop.amout + ' ' + prop.label}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid item sm={12} md={6}>
                            <Typography variant="caption" component="div" className={classes.pricelistDescription}>
                                {pricelistDesc}
                            </Typography>
                        </Grid>
                    </div>
                </>
            )
        }
    }

    const validateUserCardRegistration = () => {
        if (user.paymentRequestId && !user.cardNumber) {
            (async () => {
                setIntervalHandler(setInterval(async () => await dispatch(fetchUser()), 5000))
                setShowCardVerificationDialog(true)
            })()
        }
    }

    useEffect(() => {
        const fetchAndUpdate = async () => {
            try {
                if (pricingConditionsState.executed && selectedConnector) {
                    const response = await dispatch(fetchPricingConditionsForConnector(selectedConnector.id))
                    if (await response) {
                        pricingConditionsState.value = response;                        
                        // Adjust the condition based on your actual response structure
                        updateSelectedPricingCondition(selectedPricingCondition?.id)
                    }
                }
            } catch (error) {
                console.error('Failed to fetch pricing conditions', error)
            }
        }

        if (selectedConnector) {
            fetchAndUpdate()
        }
    }, [dispatch, selectedConnector])

    return (
        <>
            <Grid direction="column" container wrap="nowrap" className={classes.contentWrapper}>
                <div className={classes.scrollable}>
                    <Grid container direction="column" wrap="nowrap">
                        {location && (
                            <>
                                <Grid
                                    container
                                    justify="flex-end"
                                    alignItems="flex-end"
                                    style={{ justifyContent: 'flex-end' }}
                                >
                                    <Grid item>
                                        <Button
                                            component={RouterLink}
                                            to="/main-map"
                                            size="medium"
                                            variant="contained"
                                            color="primary"
                                            className={classes.navigateButtonWhite}
                                        >
                                            <Close style={{ height: '13px' }} />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.subTitle}>
                                    {t('charging.startChargingAt')}
                                </Grid>
                                <Grid item className={classes.title}>
                                    {location.name}
                                </Grid>
                                <Grid container alignItems="center" className={classes.selectContainer}>
                                    <Grid item style={{ width: '5px' }}></Grid>
                                    <Grid item style={{ width: 'calc(100% - 20px)' }}>
                                        <FormControl
                                            className={classes.select}
                                            disabled={!selectedCharger}
                                        >
                                            <Select
                                                IconComponent={() => <ExpandMoreIcon style={{ color: 'white' }} />}
                                                disableUnderline={true}
                                                classes={{
                                                    root: classes.selectRoot,
                                                    select: classes.selectRoot,
                                                    selectMenu: classes.selectRoot,
                                                }}
                                                value={selectedCharger?.id}
                                                onChange={onChargerSelectionChanged}
                                                onClick={upadteCurrentConnectorState}
                                                MenuProps={{ className: classes.menuProps }}
                                            >
                                                {sortedChargers.map((item, i) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                        className={classes.menuItem}
                                                        style={{
                                                            borderTop: i !== 0 ? '1px solid #484e51' : 'none',
                                                            opacity: location.connectedChargers.includes(item.id)
                                                                ? 'none'
                                                                : '0.5',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {item.name}
                                                            {!location.connectedChargers.includes(item.id) ? (
                                                                <img
                                                                    src={PowerIconRed}
                                                                    alt="Charger unavailable"
                                                                    style={{ height: '15px' }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}{' '}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {selectedConnector && location.connectedChargers.includes(selectedCharger.id) && (
                                    <Grid container alignItems="center" className={classes.selectContainer}>
                                        <Grid item style={{ width: '5px' }}></Grid>
                                        <Grid item style={{ width: 'calc(100% - 20px)' }}>
                                            <FormControl
                                                className={classes.select}
                                                disabled={!selectedConnector}
                                            >
                                                <Select
                                                    IconComponent={() => <ExpandMoreIcon style={{ color: 'white' }} />}
                                                    disableUnderline={true}
                                                    classes={{
                                                        root: classes.selectRoot,
                                                        select: classes.selectRoot,
                                                        selectMenu: classes.selectRoot,
                                                    }}
                                                    value={selectedConnector.id}
                                                    onChange={onConnetorSelectionChanged}
                                                    //onClick={upadteCurrentConnectorState}
                                                    MenuProps={{ className: classes.menuProps }}
                                                >
                                                    {selectedCharger?.connectors
                                                        .sort((x, y) => x.externalId - y.externalId)
                                                        .map((connector: any, i: number) => {
                                                            return (
                                                                <MenuItem
                                                                    key={connector.id}
                                                                    value={connector.id}
                                                                    className={classes.menuItem}
                                                                    style={{
                                                                        borderTop:
                                                                            i !== 0 ? '1px solid #484e51' : 'none',
                                                                        opacity:
                                                                            connector.availability === 0 &&
                                                                            (connector.state === 0 ||
                                                                                connector.state === 10) &&
                                                                            location.connectedChargers.includes(
                                                                                connector.chargerId
                                                                            )
                                                                                ? 'none'
                                                                                : '0.5',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        {getConnectorLabel(
                                                                            t,
                                                                            connector.socketType,
                                                                            connector.state,
                                                                            connector.externalId,
                                                                            connector.inPreparingState
                                                                        )}

                                                                        {getConncetorStateIcon(
                                                                            connector.state,
                                                                            connector.availability
                                                                        )}
                                                                    </div>
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )}
                                {selectedConnector && pricingConditionsState.executed && pricingConditionsState.value?.length != 0 ? (
                                    <Grid container alignItems="center" className={classes.selectContainer}>
                                        <Grid item style={{ width: '5px' }}></Grid>
                                        <Grid item style={{ width: 'calc(100% - 20px)' }}>
                                            <FormControl
                                                className={classes.select}
                                                disabled={!pricingConditionsState.executed}
                                            >
                                                <Select
                                                    IconComponent={() => <ExpandMoreIcon style={{ color: 'white' }} />}
                                                    disableUnderline={true}
                                                    classes={{
                                                        root: classes.selectRoot,
                                                        select: classes.selectRoot,
                                                        selectMenu: classes.selectRoot,
                                                    }}
                                                    value={selectedPricingCondition ? selectedPricingCondition.id : ''}
                                                    onChange={(event) =>
                                                        updateSelectedPricingCondition(event.target.value)
                                                    }
                                                    MenuProps={{ className: classes.menuProps }}
                                                >
                                                    {pricingConditionsState.executed &&
                                                        pricingConditionsState.value?.map(
                                                            (pricingCondition: PricingConditionModel, i: number) => (
                                                                <MenuItem
                                                                    value={pricingCondition.id}
                                                                    key={pricingCondition.id}
                                                                    className={classes.menuItem}
                                                                    style={
                                                                        i !== 0
                                                                            ? { borderTop: '1px solid #484e51' }
                                                                            : {}
                                                                    }
                                                                >
                                                                    {pricingCondition.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                ) : (location.connectedChargers.includes(selectedCharger.id) && (                        
                                <Grid container direction="column" wrap="nowrap" className={classes.pricelistDescription}>
                                    <Grid item sm={12} md={6}>
                                        "There is no pricing condition defined for this connector"
                                    </Grid>
                                </Grid>)
                                ) }
                            </>
                        )}
                    </Grid>
                    {selectedPricingCondition ? (
                        <Grid container direction="column" wrap="nowrap">
                            <Grid item sm={12} md={6}>
                                {renderPricingConditionDetails()}
                            </Grid>
                        </Grid>
                    ) : (
                        ''
                    )}
                </div>
                <div className={classes.bottomDecoration}></div>
                <BottomControlPanel
                    actualPage="none"
                    positionAbsolute={true}
                    asyncButton={
                        <AsyncButton
                            buttonProps={{
                                disabled: !allowCharging,

                                className: allowCharging
                                    ? classes.startChargingButton
                                    : classes.startChargingButtonDisbled,
                                onClick: async (event: any) => {
                                    event.preventDefault()
                                    if (selectedCharger && selectedConnector && selectedPricingCondition.id) {
                                        if (
                                            !freeChargingAllowed()
                                        ) {
                                            if (isAnonymous) {
                                                setDialogMessage('charging.registerToPay')
                                            } else {
                                                if (user.cardNumber) {
                                                    startGetSession()
                                                }
                                                if (!user.paymentRequestId) {
                                                    history.push(
                                                        `/register-card?location=${location.id}&charger=${selectedCharger.externalId}&connector=${selectedConnector.externalId}&pricelist=${selectedPricingCondition.id}`
                                                    )
                                                }
                                                validateUserCardRegistration()
                                            }
                                        } else {
                                            startGetSession()
                                        }
                                    }
                                },
                            }}
                            inProgress={inProgress}
                        >
                            <div className={classes.navigateButtonContent}>
                                <div>{t('charging.startCharging')}</div>

                                <img alt="Power" className={classes.navigateIcon} src={PowerIcon} />
                            </div>
                        </AsyncButton>
                    }
                />

                <Dialog open={!!showCardVerificationDialog} className={classes.dialog}>
                    <Grid
                        container
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}
                    >
                        <div className={classes.dialogTitle}>{t('card.pleaseWait')}</div>
                    </Grid>
                    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={16} className={classes.titleProgressSpinner}></CircularProgress>
                    </Grid>
                    <DialogContent className={classes.dialogContent}>
                        <Typography className={classes.dialogTextDesc}>{t('card.awaitingNotification')}</Typography>
                    </DialogContent>
                </Dialog>

                <Dialog open={Boolean(dialogMessage)} className={classes.dialog}>
                    <Grid
                        container
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px',
                        }}
                    >
                        <div className={classes.dialogTitle}>{t('card.notification')}</div>
                    </Grid>
                    <DialogContent className={classes.dialogContent}>
                        <Typography className={classes.dialogTextDesc}>
                            {t(dialogMessage)}
                            {dialogMessage === 'charging.registerToPay' && (
                                <RouterLink to="/register" style={{ color: '#00e3e3' }}>
                                    {t('login.register')}
                                </RouterLink>
                            )}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.closeButton} onClick={() => setDialogMessage('')}>
                            <p className={classes.closeText} style={{ color: '#ffa3a3' }}>
                                {t('common.close')}
                            </p>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </>
    )
}
